import React from "react"
import { Link } from "gatsby"
import { withNamespaces } from "react-i18next";
import Swiper from 'react-id-swiper';

import Layout from "../components/layout"
import SEO from "../components/seo"
import NavBar from "../components/navbar"
import Footer from "../components/footer"
import Loader from "../components/loader"
import Particles from "../components/particles"
import 'swiper/css/swiper.css';

import {Helmet} from "react-helmet";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]',{
		speed: 1000,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	})
}

class IndexPage extends React.Component {

    componentDidMount() {
        window.location.replace("https://www.atlantide.io/");
    }    

    render() {
        const {t, i18n} = this.props;
        const ln = i18n.language.split("-")[0];

        const swiperParams = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            centeredSlides: true,
            loop: true,   
            navigation: {
                nextEl: '.swiper-button-next hidden',
                prevEl: '.swiper-button-prev hidden'
            },
            spaceBetween: 30,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false
             },          
        }
        
        return (
            <Layout>
                <SEO title="Play with history" lang={ln}/>  
                <Loader />
                {/* FIRST PART WITH PATICULES */}
                <div id="landing-hero" className="hero is-relative is-theme-secondary hero-waves">
                    <Particles className="particles-js" />
                    <NavBar siteTitle="Atlantide IO" transparent/>

                    <div id="main-hero" className="hero-body ">
                        <div className="container">
                            <div className="columns is-vcentered is-tablet-portrait">
                                <div className="column is-5 header-caption has-text-left">
                                    <div className="logo-container">
                                        <img className="light-logo big" src={require("../images/logos/atlantide_logo_big.svg")} alt="atlantide logo"/>
                                        
                                        <h1 className="landing-title">
                                            {t("title")}
                                        </h1>
                                        <h2 className="subtitle is-5 light-text">
                                            {t("subtitle")}
                                        </h2>
                                    </div>
                                    <p className="vertical-align-flex pt-10 pb-10 hidden" id="discover">
                                        <Link to="/#presentation" className="button button-cta btn-align btn-outlined is-bold light-btn  z-index-2">
                                            {t("discover")}
                                        </Link>
                                    </p>
                                </div>
                                <span id="store-container-index">
                                    <div className="navbar-item">
                                        <a rel="noopener" id="app-store" href="https://apps.apple.com/fr/app/atlantide-io/id1364973121" target="_blank" className="raised store">
                                            <img src={require("../images/illustrations/icons/app_store_"+ln+".png")} alt="download on appstore"/>
                                        </a>
                                    </div>
                                    <div className="navbar-item">
                                        <a rel="noopener" id="playstore" href="https://play.google.com/store/apps/details?id=com.magnitudelabs.atlantide" target="_blank" className="raised store">
                                            <img src={require("../images/illustrations/icons/play_store_"+ln+".png")} alt="download on playstore"/>
                                        </a>
                                    </div>
                                </span>
                                <div className="column is-9 is-offset-1">
                                    <img src={require("../images/illustrations/UI/devices_small.png")} alt="small device screen for video" id='small_device'/>
                                    <figure className="image is-3by2" id='devices'>
                                        <img src={require("../images/illustrations/UI/devices.png")} alt="device screen for video" id='device'/>
                                        <div id="youtube-container">
                                            {
                                                (true || ln === "fr")?
                                                <iframe frameBorder="0" height="100%" width="100%" style={{zIndex:1}}
                                                    src="https://youtube.com/embed/8bf5PD_0-kw?&playlist=8bf5PD_0-kw&version=3&loop=1&autoplay=1&controls=0&showinfo=0&autohide=1&mute=1">
                                                </iframe>
                                                : 
                                                <img src={require("../images/illustrations/UI/screen-video.jpg")} style={{zIndex:0,objectFit:"contain", backgroundColor:"black"}}/>
                                            }
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END FIRST PART WITH PATICULES */}

                <div className="toppartnersMajorContainer">
                    <span>{t("supported")}</span>
                    <div  className="toppartnersContainer">
                        <img src={require("../images/logos/partners/top/edljc.jpg")} className="topPartners"/>
                        <img src={require("../images/logos/partners/top/edtech.jpg")} className="topPartners"/>
                        <img src={require("../images/logos/partners/top/ubi.jpg")} className="topPartners"/>
                        <img src={require("../images/logos/partners/top/fdp.jpg")} className="topPartners"/>
                        <img src={require("../images/logos/partners/top/paris.jpg")} className="topPartners"/>
                    </div>
                </div>

                {/* SECOND PART WITH ICONS*/}
                <section id="presentation" className="section is-medium">
                <div className="container">
                        
                    <div className="section-title-wrapper has-text-centered">
                        <div className="bg-number">#</div>
                        <h2 className="section-title-landing">
                            {t("rediscoverH.title")}
                        </h2>
                        <h4>
                            {t("rediscoverH.paraph")}
                        </h4>
                    </div>

                    <div className="content-wrapper">
                        <div className="columns is-vcentered is-multiline has-text-centered">
                            <div className="column is-3">
                                <div className="wavy-icon-box">
                                    <div className="is-icon-reveal">
                                        <i className="im im-icon-Street-View"></i>
                                    </div>
                                    <div className="box-title">{t("rediscoverH.option1.title")}</div>
                                    <p className="box-content is-tablet-padded">
                                        {t("rediscoverH.option1.paraph")}
                                    </p>
                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="wavy-icon-box">
                                    <div className="is-icon-reveal">
                                        <i className="im im-icon-Family-Sign"></i>
                                    </div>
                                    <div className="box-title">{t("rediscoverH.option2.title")}</div>
                                    <p className="box-content is-tablet-padded">
                                        {t("rediscoverH.option2.paraph")}
                                    </p>
                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="wavy-icon-box">
                                    <div className="is-icon-reveal">
                                        <i className="im im-icon-Gamepad"></i>
                                    </div>
                                    <div className="box-title">{t("rediscoverH.option3.title")}</div>
                                    <p className="box-content is-tablet-padded">
                                        {t("rediscoverH.option3.paraph")}
                                    </p>
                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="wavy-icon-box">
                                    <div className="is-icon-reveal">
                                        <i className="im im-icon-Hand-TouchSmartphone"></i>
                                    </div>
                                    <div className="box-title">{t("rediscoverH.option4.title")}</div>
                                    <p className="box-content is-tablet-padded">
                                        {t("rediscoverH.option4.paraph")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    
                        
                        <div className="columns is-vcentered is-multiline has-text-centered">
                            <div className="column is-3">
                            </div>
                            <div className="column is-3">
                                <div className="wavy-icon-box">
                                    <div className="is-icon-reveal">
                                        <i className="im im-icon-Wheelchair"></i>
                                    </div>
                                    <div className="box-title">{t("rediscoverH.option5.title")}</div>
                                    <p className="box-content is-tablet-padded">
                                        {t("rediscoverH.option5.paraph")}
                                    </p>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="wavy-icon-box">
                                    <div className="is-icon-reveal">
                                        <i className="im im-icon-Student-Hat2"></i>
                                    </div>
                                    <div className="box-title">{t("rediscoverH.option6.title")}</div>
                                    <p className="box-content is-tablet-padded">
                                        {t("rediscoverH.option6.paraph")}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="has-text-centered pt-40 pb-40">
                            <a rel="noopener" href="#features" className="button button-cta primary-btn raised"> {t("knowMore")}</a>
                        </div>
                    </div>
                </div>
                </section>
                {/* END SECOND PART WITH ICONS*/}

                {/* FOURTH PART WITH SCENES */}
                <section className="section section-feature-grey is-medium" id="features">
                <div className="container">

                    <div className="content-wrapper">
                        <div className="columns is-vcentered pb-40 pt-40">
                            <div className="column is-4 is-offset-1">
                                <div className="icon-subtitle"><i className="im im-icon-Location-2"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <div>
                                        {t("features.option1.title")}
                                    </div>
                                    <div className="bg-number"></div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                        {t("features.option1.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#" className="button btn-align btn-more is-link color-primary">
                                        Learn more <i className="sl sl-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="column is-6 is-offset-1 has-text-centered">
                                <img className="" src={require("../images/illustrations/drawings/ob-01-Walking.svg")} alt="illustration outdoor game"/>
                            </div>
                        </div>

                        <div className="columns is-vcentered pb-40 pt-40">
                            <div className="column is-4 is-offset-1 is-hidden-tablet is-hidden-desktop">
                                <div className="icon-subtitle"><i className="im im-icon-University"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>
                                        {t("features.option2.title")}
                                    </h2>
                                    <div className="bg-number"></div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                        {t("features.option2.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#" className="button btn-align btn-more is-link color-primary">
                                        Learn more <i className="sl sl-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="column is-6 has-text-centered">
                                <img src={require("../images/illustrations/drawings/ob-02-Monuments.svg")} alt="illustration monument and history"/>
                            </div>
                            <div className="column is-4 is-offset-1 is-hidden-mobile">
                                <div className="icon-subtitle"><i className="im im-icon-University"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>
                                        {t("features.option2.title")}
                                    </h2>
                                    <div className="bg-number"></div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("features.option2.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#" className="button btn-align btn-more is-link color-primary">
                                        Learn more <i className="sl sl-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="columns is-vcentered pb-40 pt-40">
                            <div className="column is-4 is-offset-1">
                                <div className="icon-subtitle"><i className="im im-icon-Business-ManWoman"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>
                                        {t("features.option3.title")}
                                    </h2>
                                    <div className="bg-number"></div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                        {t("features.option3.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#" className="button btn-align btn-more is-link color-primary">
                                        Learn more <i className="sl sl-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="column is-6 is-offset-1 has-text-centered">
                                <img className="" src={require("../images/illustrations/drawings/ob-03-Family.svg")} alt="illustration family game"/>
                            </div>
                        </div>

                        <div className="has-text-centered is-title-reveal pt-20 pb-20">
                            <a rel="noopener" href="#first-feature" className="button button-cta primary-btn  raised">{t("features.button")}</a>
                        </div>
                    </div>
                </div>
                </section> 
                {/* END FOURTH PART WITH SCENES */}


                {/* Phone scroller section 0 */}
                <section id="first-feature" className="section no-margin has-border-top">
                    <div className="container">
                        <div className="columns is-gapless is-vcentered mt-80 mb-80">
                            {/* Smartphone */}
                            <div className="column is-7 is-hidden-touch">
                                <div className="phone-slide is-zero">
                                </div>
                            </div>
                            {/* Content */}
                            <div className="column slide-description">
                                <div className="icon-subtitle"><i className="im im-icon-Police"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>{t("phone.option0.title")}</h2>
                                    <div className="bg-number"></div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("phone.option0.paraph-1")}<br/>
                                    {t("phone.option0.paraph-2")}<br/>
                                    {t("phone.option0.paraph-3")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#second-feature" className="button button-cta btn-align btn-outlined is-bold raised primary-btn  is-hidden-touch">
                                        Next feature
                                    </a>
                                </div>   
                            </div>
                
                        </div>
                    </div>
                </section>
                {/* /Phone scroller section 0 */}

                {/* Phone scroller section 1 */}
                <section id="first-feature" className="section no-margin has-border-top">
                    <div className="container">
                        <div className="columns is-gapless is-vcentered mt-80 mb-80">
                            {/* Smartphone */}
                            <div className="column is-7 is-hidden-touch">
                                <div className="phone-slide is-first">
                                </div>
                            </div>
                            {/* Content */}
                            <div className="column slide-description">
                                <div className="icon-subtitle"><i className="im im-icon-Map"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>{t("phone.option1.title")}</h2>
                                    <div className="bg-number">1</div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("phone.option1.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#second-feature" className="button button-cta btn-align btn-outlined is-bold raised primary-btn  is-hidden-touch">
                                        Next feature
                                    </a>
                                </div>   
                            </div>
                
                        </div>
                    </div>
                </section>
                {/* /Phone scroller section 1 */}
                
                {/* Phone scroller section 2 */}
                <section id="second-feature" className="section section-feature-grey no-margin">
                    <div className="container">
                
                        <div className="columns is-gapless is-vcentered mt-80 mb-80">
                            <div className="column is-7 is-hidden-touch">
                                <div className="phone-slide is-second">
                                </div>
                            </div>
                
                            <div className="column slide-description">
                                <div className="icon-subtitle"><i className="im im-icon-Key-Lock"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>{t("phone.option2.title")}</h2>
                                    <div className="bg-number">2</div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("phone.option2.paraph-1")}<br/>
                                    {t("phone.option2.paraph-2")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#third-feature" className="button button-cta btn-align btn-outlined is-bold raised primary-btn  is-hidden-touch">
                                        Next feature
                                    </a>
                                </div> 
                            </div>
                
                        </div>
                    </div>
                </section>
                {/* /Phone scroller section 2 */}
                
                {/* Phone scroller section 3 */}
                <section id="third-feature" className="section no-margin">
                    <div className="container">
                
                        <div className="columns is-gapless is-vcentered mt-80 mb-80">
                            <div className="column is-7 is-hidden-touch">
                                <div className="phone-slide is-third">
                                </div>
                            </div>
                
                            <div className="column slide-description">
                                <div className="icon-subtitle"><i className="im im-icon-Add-File"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>{t("phone.option3.title")}</h2>
                                    <div className="bg-number">3</div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("phone.option3.paraph-1")}<br/>
                                    {t("phone.option3.paraph-2")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#fourth-feature" className="button button-cta btn-align btn-outlined is-bold raised primary-btn  is-hidden-touch">
                                        Next feature
                                    </a>
                                </div>   
                            </div>
                
                        </div>
                    </div>
                </section>
                {/* /Phone scroller section 3 */}
                
                {/* Phone scroller section 4 */}
                <section id="fourth-feature" className="section section-feature-grey no-margin">
                    <div className="container">
                
                        <div className="columns is-gapless is-vcentered mt-80 mb-80">
                            <div className="column is-7 is-hidden-touch">
                                <div className="phone-slide is-fourth">
                                </div>
                            </div>
                
                            <div className="column slide-description">
                                <div className="icon-subtitle"><i className="im im-icon-Queen-2"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>{t("phone.option4.title")}</h2>
                                    <div className="bg-number">4</div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("phone.option4.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#!" className="button button-cta btn-align raised primary-btn  is-hidden-touch">
                                        Get started
                                    </a>
                                </div> 
                            </div>
                
                        </div>
                    </div>
                </section>
                {/* /Phone scroller section 4 */}
                
                {/* Phone scroller section 5 */}
                <section id="fifth-feature" className="section  no-margin">
                    <div className="container">
                
                        <div className="columns is-gapless is-vcentered mt-80 mb-80">
                            <div className="column is-7 is-hidden-touch">
                                <div className="phone-slide is-fifth">
                                </div>
                            </div>
                
                            <div className="column slide-description">
                                <div className="icon-subtitle"><i className="im im-icon-Haunted-House"></i></div>
                                <div className="title quick-feature is-handwritten text-bold">
                                    <h2>{t("phone.option5.title")}</h2>
                                    <div className="bg-number">5</div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="section-feature-description">
                                    {t("phone.option5.paraph")}
                                </span>
                                <div className="pt-10 pb-10 hidden">
                                    <a rel="noopener" href="#!" className="button button-cta btn-align raised primary-btn  is-hidden-touch">
                                        Get started
                                    </a>
                                </div> 
                            </div>
                
                        </div>
                    </div>
                </section>
                {/* /Phone scroller section 4 */}
                
                {/* Event Cards section */}
                <section className="section section-feature-grey is-medium">
                    <div className="container">
                        {/* Title */}
                        <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number">#</div>
                            <h2 className="section-title-landing">
                                {t("missions.title")}
                            </h2>
                            <h4>
                                {t("missions.subtitle")}
                            </h4>
                        </div>
                        {/* /Title */}

                        <div className="content-wrapper">
                            <div className="columns">
                                <div className="column is-4">
                                    {/* Event Card */}
                                    <div className="event-card is-wavy">
                                        <div className="card-date hidden">
                                            <div className="date">
                                                <span className="day">24</span>
                                                <span className="month">Aug</span>
                                            </div>
                                        </div>
                                        <div className="img-container">
                                            <img src={t("missions.mission-1.cover")} alt="mission cover"/>
                                        </div>
                                        <div className="card-text">
                                            <div className="text text-container">
                                                <div className="text text-header">
                                                    <h2 className="text text-title">{t("missions.mission-1.title")}</h2>
                                                    <p className="text text-subtitle">{t("missions.mission-1.city")}</p>
                                                </div>
                                                <div className="text text-details">
                                                    <p className="text text-description">{t("missions.mission-1.pitch")}
                                                    </p>
                                                    <a rel="noopener" href={"./app/missions/"+t("missions.mission-1.id")} className="button btn-align btn-more is-link color-accent mt-10 mb-10">
                                                        {t("missions.knowmore")} <i className="sl sl-icon-arrow-right"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Event Card */}
                                </div>
                                <div className="column is-4">
                                    {/* Event Card */}
                                    <div className="event-card is-wavy">
                                        <div className="card-date hidden">
                                            <div className="date">
                                                <span className="day">19</span>
                                                <span className="month">Sep</span>
                                            </div>
                                        </div>
                                        <div className="img-container">
                                            <img src={t("missions.mission-2.cover")} alt="mission cover"/>
                                        </div>
                                        <div className="card-text">
                                            <div className="text text-container">
                                                <div className="text text-header">
                                                    <h2 className="text text-title">{t("missions.mission-2.title")}</h2>
                                                    <p className="text text-subtitle">{t("missions.mission-2.city")}</p>
                                                </div>
                                                <div className="text text-details">
                                                    <p className="text text-description">
                                                        {t("missions.mission-2.pitch")}
                                                    </p>
                                                    <a rel="noopener" href={"./app/missions/"+t("missions.mission-2.id")} className="button btn-align btn-more is-link color-accent mt-10 mb-10">
                                                        {t("missions.knowmore")} <i className="sl sl-icon-arrow-right"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Event Card */}
                                </div>
                                <div className="column is-4">
                                    {/* Event Card */}
                                    <div className="event-card is-wavy">
                                        <div className="card-date hidden">
                                            <div className="date">
                                                <span className="day">05</span>
                                                <span className="month">Dec</span>
                                            </div>
                                        </div>
                                        <div className="img-container">
                                            <img src={t("missions.mission-3.cover")} alt="mission cover"/>
                                        </div>
                                        <div className="card-text">
                                            <div className="text text-container">
                                                <div className="text text-header">
                                                    <h2 className="text text-title">{t("missions.mission-3.title")}</h2>
                                                    <p className="text text-subtitle">{t("missions.mission-3.city")}</p>
                                                </div>
                                                <div className="text text-details">
                                                    <p className="text text-description">{t("missions.mission-3.pitch")}
                                                    </p>
                                                    <a rel="noopener" href={"./app/missions/"+t("missions.mission-3.id")} className="button btn-align btn-more is-link color-accent mt-10 mb-10">
                                                        {t("missions.knowmore")} <i className="sl sl-icon-arrow-right"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Event Card */}
                                </div>
                            </div>
                            
                            <div className="is-title-reveal pt-20 pb-20" style={{textAlign:"right"}}>
                                <Link to="./app/missions/" className="button button-cta primary-btn  raised">{t("missions.seelist")}</Link>
                            </div> 
                        </div>
                    </div>

               
                
                
                
                <div id="newsletter">
                    <div className="section-title-wrapper">
                        <div className="icon-subtitle" style={{marginBottom:-70, opacity:0.05, zIndex:-1}}><i className={"im im-icon-Gift-Box"} style={{color:"black", fontSize:"6em"}}></i></div>
                        <h2 className="section-title-landing has-text-centered dark-text" style={{letterSpacing:2}}>
                            {t("news_title")}
                        </h2>
                    </div>
                    <div id="mc_embed_signup">
                        <form action="https://atlantide.us12.list-manage.com/subscribe/post?u=49c4e2ab145c8ebc381577a73&amp;id=207142dc7c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll">
                                <div className="mc-field-group">
                                    <input type="email" placeholder={t("enter_mail")} name="EMAIL" className="required email" id="mce-EMAIL"/>
                                    <input type="submit" value={t("subscribe")} name="subscribe" id="mc-embedded-subscribe" className="button button-cta primary-btn  raised"/>
                                </div>
                                <div id="mce-responses" class="clear">
                                    <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                                    <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                                </div>    
                                <div style={{position: "absolute", left: -5000}} aria-hidden="true"><input type="text" name="b_49c4e2ab145c8ebc381577a73_207142dc7c" tabindex="-1" value=""/></div>
                            </div>
                        </form>
                    </div>             
                </div>
                </section>
                {/* /Cards section */}



                <section className="others">
                    <div className="block first">
                        <p>
                            {t("teasing-studio")}
                        </p>
                        <img src={require('../images/illustrations/UI/logo_as.png')} className="logo"/>
                        
                        <img src={require('../images/illustrations/UI/bg_as.jpg')} className="cover"/>

                        <a rel="noopener" href="https://studio.atlantide.io" target="_blank" className="button button-cta btn-align raised primary-btn">
                            {t("missions.knowmore")}
                        </a>
                    </div>
                    <div className="block second">
                        <img src={require('../images/illustrations/UI/logo_ad.png')} className="cover ad"/>
                        <p>
                            {t("teasing-archive")}
                        </p>
                        <h1>{t("teasing-archive-title")}</h1>
                        <a href="https://archives-game.atlantide.io/" target="_blank" className="button button-cta btn-align raised primary-btn">
                            {t("missions.knowmore")}
                        </a>

                    </div>
                </section>

                {/*Clients grid */}
                <section id="trust" className="section is-medium">
                <div className="container">
                    {/*Title */}
                    <div className="section-title-wrapper">
                        <div className="bg-number"><i className="material-icons">grade</i></div>
                        <h2 className="section-title-landing has-text-centered dark-text">{t("partners.title")}</h2>
                    </div>
                    {/*Grid */}
                    <div className="content-wrapper">
                        <div className="grid-clients five-grid">

                                <div className="columns is-vcentered">
                                    <div className="column is-hidden-mobile"></div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/edtech.png")} alt="partner"/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/ft-montpellier.png")} alt="partner"/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/ubi.png")} alt="partner"/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/prizoners.png")} alt=""/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/bic_fr.png")} alt="partner"/></a>
                                    </div>
                                    <div className="column is-hidden-mobile"></div>
                                </div>
                                <div className="columns is-vcentered is-separator">
                                    <div className="column is-hidden-mobile"></div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/ot-montpellier-small.png")} alt=""/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/ot-toulouse-small.png")} alt=""/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={'https://events.parisinfo.com/logo_adherent_2020/logo-PJTM-partenaire-2020-FR-100dpi.jpg'} alt="partner"/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/crealia.png")} alt=""/></a>
                                    </div>
                                    {/*Client */}
                                    <div className="column">
                                        <a><img className="client" src={require("../images/logos/partners/fabres.png")} alt=""/></a>
                                    </div>
                                    <div className="column is-hidden-mobile"></div>
                                </div>
                        </div>


                        {/*CTA */}
                        <div className="has-text-centered is-title-reveal pt-40 pb-40 hidden">
                            <a href="#!" className="button button-cta btn-align primary-btn raised ">Get started Now</a>
                        </div>
                        {/*/CTA */}
                    </div>
                    


                {/* Testimonials section */}
                <section className="section is-medium"  style={{paddingTop:"0px", paddingBottom:"0px"}}>
                    <div className="container">
                    <div className="content-wrapper" style={{paddingTop:"50px", paddingBottom:"0px"}}>
                        <div className="columns testimonials">
                            <div className="column "></div>
                            
                            <Swiper {...swiperParams} >
                                {/* Testimonial item */}
                                    <div className="testimonial-item">
                                        <div className="flex-card card-overflow raised">
                                            <div className="testimonial-avatar">
                                                <img src={require("../images/logos/partners/ubi.png")} alt="Ubisoft logo"/>
                                            </div>
                                            <div className="testimonial-content">
                                                <p>
                                                    {t("partners.ubisoft")}
                                                    <a className="testi-link" href='https://www.ubisoft.com/en-us/company/start-ups/station-f.aspx' target="_blank">
                                                        <i className="im im-icon-Link"></i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                {/* /Testimonial item */}

                                {/* Testimonial item */}
                                    <div className="testimonial-item">
                                        <div className="flex-card card-overflow raised">
                                            <div className="testimonial-avatar">
                                                <img src={require("../images/logos/partners/fdp.jpg")} alt="Fondation du patrimoine logo"/>
                                            </div>
                                            <div className="testimonial-content">
                                                <p>
                                                    {t("partners.fdp")}
                                                    <a className="testi-link" href='https://www.fondation-patrimoine.org/fondation-du-patrimoine/languedoc-roussillon/presentation' target="_blank">
                                                        <i className="im im-icon-Link"></i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                {/* /Testimonial item */}

                                {/* /Testimonial item */}
                                    <div className="testimonial-item">
                                        <div className="flex-card card-overflow raised">
                                            <div className="testimonial-avatar">
                                                <img src={require("../images/logos/partners/edljc.png")} alt="École du louvre Junior Conseil logo"/>
                                            </div>
                                            <div className="testimonial-content">
                                                <p>
                                                    {t("partners.edljc")}
                                                    <a className="testi-link" href="https://ecoledulouvrejuniorconseil.com/" target="_blank">
                                                        <i className="im im-icon-Link"></i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                {/* /Testimonial item */}
                            </Swiper>

                            

                            <div className="column"></div>
                        </div>
                    </div>
                </div>
                </section>
                {/* /Testimonials section */}


                </div>
                </section>
                {/*Clients */}

                <Footer />
            </Layout>
        )

    } 
}

export default withNamespaces("IndexPage")(IndexPage);
